/* LiveSessionCard.css */
.ring {
  position: relative;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ring i {
  position: absolute;
  inset: 0;
  border: 2px solid #fff;
  transition: 0.5s;
  border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
  animation: animate 6s linear infinite;
}

.ring i:nth-child(2) {
  animation: animate 4s linear infinite;
  border-radius: 41% 44% 56% 59%/38% 62% 63% 37%;
}

.ring i:nth-child(3) {
  animation: animate2 10s linear infinite;
  border-radius: 41% 44% 56% 59%/38% 62% 63% 37%;
}

.ring i {
  border: 4px solid var(--clr);
  filter: drop-shadow(0 0 8px var(--clr));
}

.ring.paused i {
  animation-play-state: paused;
  opacity: 0.3;
}

.vinyl {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 2;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
