.user-menu {
  position: relative;
  display: inline-block;
}

.user-menu button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.menu-dropdown {
  position: absolute;
  right: 0;
  top: 2.5rem;
  background-color: white;
  border: 1px solid #ccc;
  padding: 0.5rem;
  z-index: 1000;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
}

.menu-dropdown a,
.menu-dropdown button {
  display: block;
  padding: 0.5rem;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
}

.menu-dropdown a:hover,
.menu-dropdown button:hover {
  background-color: #f0f0f0;
}
